// import React from "react";
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Head from "../components/head";
import imageStyles from "./images.module.css";

import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulPhotos {
      edges {
        node {
          title {
            title
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

class images extends React.Component {
  state = {
    currentImage: 0,
    setCurrentImage: 0,
    viewerIsOpen: false,
    setViewerIsOpen: false,
    photos: [],
  };

  componentDidMount() {
    //set them to state after making array of objets.

    // let tempArr = this.props.data.allContentfulPhotos.edges.forEach(edge => {
    //   if (edge.node.title !== "null") {
    //     let rkey = {
    //       src: edge.node.image.file.url,
    //       width: 4,
    //       height: 3,
    //       title: edge.node.title.title,
    //     };

    //     return rkey;
    //   }
    // });
    let tempArr = [];
    for (let i = 0; i < this.props.data.allContentfulPhotos.edges.length; i++) {
      if (
        this.props.data.allContentfulPhotos.edges[i].node.title.title !== "null"
      ) {
        let rkey = {
          src: this.props.data.allContentfulPhotos.edges[i].node.image.file.url,
          width: 4,
          height: 3,
          title: this.props.data.allContentfulPhotos.edges[i].node.title.title,
        };

        tempArr.push(rkey);
      }
    }
    this.setState({ photos: tempArr }, () => console.log(this.state.photos));
  }
  openLightbox = (event, { photo, index }) => {
    this.setState({ currentImage: index, viewerIsOpen: true });
  };

  closeLightbox = () => {
    this.setState({ currentImage: 0, viewerIsOpen: false });
  };
  render() {
    return (
      <>
      <Head title="TSS images"></Head>
        <div className={imageStyles.imageContainer}>
          <h1>Photos</h1>
          {this.props.data.allContentfulPhotos.edges.length === 1 &&
          this.props.data.allContentfulPhotos.edges[0].node.title.title ===
            "null" ? (
            <div>
              <h3 style={{textAlign:"center"}}>No photos have been uploaded</h3>
            </div>
          ) : (
            <div>
              <Gallery photos={this.state.photos} onClick={this.openLightbox} />
              <ModalGateway>
                {this.state.viewerIsOpen ? (
                  <Modal onClose={this.closeLightbox}>
                    <Carousel
                      currentIndex={this.state.currentImage}
                      views={this.state.photos.map(x => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default images;
